<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Date picker -->
          <b-col cols="12" md="6" class="pr-2">
            <b-row>
              <b-col md="5 p-0 mr-1">
                <label for="example-input">From Date</label>
                <b-form-datepicker
                  id="from-date"
                  :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                  placeholder="Choose a date"
                  v-model="search_filter.from_date"
                />
              </b-col>
              <b-col md="5 p-0">
                <label for="example-input">To Date</label>
                <b-form-datepicker
                  id="to-date"
                  :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                  placeholder="Choose a date"
                  v-model="search_filter.to_date"
                />
              </b-col>
              <b-col md="1 d-flex align-items-end">
                <b-button @click="getCompanyBirdInventory()" variant="primary ">
                  Filter
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mt-2 d-flex justify-content-end align-items-center"
          >
            <div>
              <b-form-input
                type="text"
                class="form-control"
                placeholder="Search"
                @input="getCompanyBirdInventory()"
                v-model.trim="search_filter.input_field"
                style="border-radius: 4px"
              />
            </div>
            <b-button
              @click="clearFilter"
              variant="secondary
 			 ml-1"
            >
              Clear
            </b-button>
            <b-button @click="excelDownload()" variant="warning" class="ml-1">
              <feather-icon icon="DownloadIcon" size="16" />
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div v-if="eggInventory && eggInventory.total > 0">
        <b-table
          id="egg-stock"
          responsive
          :items="eggInventory.data"
          :fields="fields"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(index)="data">
            <div class="ml-1">
              {{ eggInventory.from + data.index }}
            </div>
          </template>
          <template #cell(total_egg)="data">
            <div class="ml-1">
              {{ data.item.purchase ? data.item.purchase.egg_qty : 0 }}
            </div>
          </template>

          <!-- Column: Id -->
          <template #cell(id)="data"> #{{ data.value }} </template>
        </b-table>
        <div class="d-flex justify-content-end mb-3 mt-2 mr-2">
          <b-button variant="primary">
            Total Egg Stock : {{ this.egg_stock ? egg_stock : 0 }}
          </b-button>
        </div>
        <div>
          <b-pagination
            :value="eggInventory.current_page"
            :total-rows="eggInventory.total"
            :per-page="eggInventory.per_page"
            align="right"
            @change="getCompanyBirdInventory"
          ></b-pagination>
        </div>
      </div>
      <div v-else class="d-flex flex-column align-items-center py-5">
        <b-img
          :src="
            require('@/assets/images/svg/infographics/no_data_infographic.svg')
          "
          class="mr-2"
        />
        <h4 class="mt-2 Secondary">Bird stock is Empty</h4>
      </div>
    </b-card>
  </div>
</template>

<script>

import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      selected: null,
      searchQuery: null,
      search_filter: {
        input_field: "",
        to_date: "",
        from_date: "",
      },
      egg_stock: null,
      fields: [
        { key: "index", label: "S.no" },
        {
          key: "shed",
          label: "Shed No.",
          // formatter: (value) => {
          // 	return value.charAt(0).toUpperCase() + value.slice(1);
          // },
        },
        "productionType",
        {
          key: "hatch",
          label: "Hatch No.",
          // formatter: (value) => {
          // 	return value.charAt(0).toUpperCase() + value.slice(1);
          // },
        },
        {
          key: "flock_age",
          label: "Age",
        },
        { key: "closing_stock", label: "Closing Stock" },
      ],
      eggInventory: {},
      total_egg_count: null,
    };
  },
  created: function () {
    // this.getCompanyBirdInventory();
    // this.getCompanyBirdCount();
  },
  methods: {
    moment() {
      return moment();
    },
    getCompanyBirdInventory(pageNo = 1) {
      let url = `web/get-egg-stock`;
      let queryParams = {
        page: pageNo,
      };

      if (this.search_filter.input_field) {
        queryParams.input_field = this.search_filter.input_field;
      }
      if (this.search_filter.to_date) {
        queryParams.to_date = this.search_filter.to_date;
      }
      if (this.search_filter.from_date) {
        queryParams.from_date = this.search_filter.from_date;
      }

      axiosIns
        .get(this.addQueryString(url, queryParams))
        .then((response) => {
          this.eggInventory = response.data.eggInventory;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getCompanyBirdCount() {
      let url = `web/farm/get-egg-count`;
      axiosIns
        .get(url)
        .then((response) => {
          this.egg_stock = response.data.egg_stock;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    excelDownload() {
      if (this.search_filter.from_date && this.search_filter.to_date) {
        this.search_filter.from_date = moment(
          this.search_filter.from_date
        ).format("YYYY-MM-DD");
        this.search_filter.to_date = moment(this.search_filter.to_date).format(
          "YYYY-MM-DD"
        );
      }
      let order_filter_data = this.prepareForm(this.search_filter);
      axiosIns
        .post(`web/export-bird-stock`, order_filter_data)
        .then((response) => {
          var tempLink = document.createElement("a");
          tempLink.style.display = "none";
          tempLink.href = response.data.file;
          tempLink.setAttribute("download", response.data.name);
          if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
          }
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          this.search_filter.to_date = null;
          this.search_filter.from_date = null;
          this.search_filter.input_field == null;
          this.getCompanyBirdInventory();
        })
        .catch((error) => {
          error.response;
          const data = error.response.data.errors;
          data, "data";
          let arr = Object.values(data).flat().toString(",");
          arr, "array";
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: arr,
            },
          });
          this.search_filter.to_date = null;
          this.search_filter.from_date = null;
          this.search_filter.input_field == null;
          this.getCompanyBirdInventory();
        });
    },

    clearFilter() {
      this.search_filter.input_field = "";
      this.search_filter.to_date = "";
      this.search_filter.from_date = "";
      this.getCompanyBirdInventory();
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
