<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Inventory</h2>
					</div>
				</div>
			</div>
		</div>
		<!-- tabs -->

		<!-- Table Container Card -->
		<b-card no-body>
			<b-tabs class="m-2" lazy>
				<b-tab active>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/chicks.svg')" style="width: 25px" />
							<span>Chicks Stock</span>
						</div>
					</template>
					<ChicksStockList />
				</b-tab>
				<!-- <b-tab>
          <template #title>
            <div>
              <b-img
                :src="require('@/assets/images/svg/bird-inventory.svg')"
                style="margin-right: 7px; width: 20px"
              />
              <span>Bird Stock</span>
            </div>
          </template>
          <BirdStockList />
        </b-tab> -->
				<b-tab lazy>
					<template #title>
						<div>
							<b-img :src="require('@/assets/images/svg/feed-supplement.svg')" style="width: 25px" />
							<span>Feed Stock</span>
						</div>
					</template>
					<FeedStockList />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/vaccine.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Vaccine</span>
						</div>
					</template>
					<vaccine-list />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<div>
							<b-img
								:src="require('@/assets/images/svg/medicine.svg')"
								style="margin-right: 7px; width: 20px"
							/>
							<span>Medicine</span>
						</div>
					</template>
					<medicine-list />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
import vSelect from "vue-select";
import FeedStockList from "./FeedStockList.vue";
import VaccineList from "./VaccineList.vue";
import MedicineList from "./MedicineList.vue";
import ChicksStockList from "./ChicksStockList.vue";
import BirdStockList from "./BirdStockList.vue";
export default {
	components: {
		vSelect,
		FeedStockList,
		VaccineList,
		MedicineList,
		ChicksStockList,
		BirdStockList,
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
